import  axios  from "axios";


const API = axios.create({ baseURL: 'http://localhost:5000' });
// const url ="http://localhost:5000/posts";


export const signIn = (formData) => API.post('/users/signin', formData);
export const signUp = (formData) => API.post('/users/signup', formData);
export const appointment = (formData) => API.post('/app/appointmet', formData);

