

export const CordData =[

    {
        icon:require("assets/img/child1.svg"),
        header:"Child Care",
        paragraph:" Find the child  care that you need today",
    },
    {
        icon:require("assets/img/grandma1.svg"),
        header:"Senior Care",
        paragraph:"Find the senior care that you need today ",
     },
    
    {
        icon:require("assets/img/pet1.svg"),
        header:"Pet Care",
        paragraph:" Find the pet care that you need today"
,    },
    // {
    //     icon:<img src={require("assets/img/pet.svg")} height={imageSize} alt="Pet Care" />,
    //     header:"Pet Care",
    //     paragraph:" Families often struggle when someone they care about needs 24-hour Home Health Care. The big question is Should we move to a nursing home facility or will it be better to live at home with a live-in caregiver? " 
    // }


]
