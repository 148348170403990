import { withStyles } from '@material-ui/core';
import React from 'react'
import {Link} from 'react-router-dom'
import { SidebarData } from "./sidebarData";
import './Navbar.css';





const styles = {

    sideMenu:{

        display: "flex",
        flexDirection: "column",
        position: "absolute",
        paddingTop: "100px",
        top: '0px',
        left: "0px",
        width: "12%",
        height: "100%",
        backgroundColor: "#253053",
        
    }
}

const SideMenu=(props)=> {

    const {classes}= props; 
    return (

        <>
        {/* <div className="nav-text">

<img src={require("assets/img/vticon.png")} height="50px" alt="Housekeeping" />
<Typography variant="h6"  align="center" color="textSecondary"> VT Exquisite Home Care</Typography>
</div> */}

        
        <div className={classes.sideMenu}>



            {SidebarData.map((item, index)=> {

            return(

                



                <li key={index} className={ item.cName}>
                <Link to={item.path}>
                {item.icon}
                <span>{item.title}</span>
                </Link>

                </li>
            )
            })}
            
        </div>
        </>
    )
}


export default withStyles(styles)(SideMenu);
