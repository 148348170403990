import React from 'react'
import useStyles from "./styles";
import { Card, CardActions, CardContent, CardMedia, Button, Typography } from '@material-ui/core/';
// import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import DeleteIcon from '@material-ui/icons/Delete';
// import moment from 'moment';
// import ThumbUpAltOutlined from '@material-ui/icons/ThumbUpAltOutlined';


export default function Avatar({worker}) {




    const classes = useStyles();
    return (
        <Card className={classes.card}>
      <CardMedia className={classes.media} image={require("./cna.jpeg")} title="This Title" />
      <div className={classes.overlay}>
        <Typography variant="h6"></Typography>
        <Typography variant="body2"> 1 min ago</Typography>
      </div>
      
      <div className={classes.overlay2}>
        <Button style={{ color: 'white' }} size="small" ><MoreHorizIcon fontSize="default" /></Button>
      </div>
      <div className={classes.details}>
        <Typography variant="body2" color="textSecondary" component="h2"></Typography> 
        
      </div>
      <Typography className={classes.title} gutterBottom variant="h5" component="h2">{worker.Name}</Typography>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">{worker.Rating}</Typography>
        <Typography variant="body2" color="textSecondary" component="p">{worker.Years_of_experience}</Typography>
        <Typography variant="body2" color="textSecondary" component="p">{worker.Rate}</Typography>
        <Typography variant="body2" color="textSecondary" component="p">{worker.Active}</Typography>
     
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button size="small" color="primary"></Button>
        {/* <Button size="small" color="primary" disabled={!user?.result} onClick={() => dispatch(deletePost(post._id))}><DeleteIcon fontSize="small" /> Delete</Button> */}
        
      </CardActions>
    </Card>
    )
}