
import * as api from '../api/index.js';

export const signin = (formData, router) => async (dispatch) => { /// The double function is used here because of the async 
  try {
    const { data } = await api.signIn(formData);  /// The try and catch is use because it is an async function as well 

    dispatch({ type: "AUTH", data });

    router.push('/login-page');
  } catch (error) {
    console.log(error);
  }
};

export const signup = (formData,router) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);
    console.log(data)
    console.log(router)

    dispatch({ type: "AUTH",playload: data });

    router.push('/login-page');
  } catch (error) {
    console.log(error);
    console.log(formData)
    console.log(router)
    
  }
};