import React from 'react'

import { Grid } from '@material-ui/core';
import WorkerReuse from '../components/reuseable/workersReuseable'
// import useStyles from "../pages/Avatar/styles";


// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//     width: 1000,
//     margin:" 40px 0px 0px 500px",
//     padding:"40px",
//   },
//   scroll:{
//     overflowY: "scroll",
//     display:"flex",
//     height:"74vh",

//   }
// }));
export default function Caregiver() {
    // const classes = useStyles();

   
    return (
       

        <Grid container direction="row" spacing="evenly">

            <Grid item xs={12} md={4} lg={8}>

            </Grid>
            <Grid item xs={12} md={4} lg={8}>

                <WorkerReuse/>
            </Grid>
        </Grid>
    
       
    );
}




 
