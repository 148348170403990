import React from 'react'
import *as FaIcons from 'react-icons/fa';
import *as AiIcons from 'react-icons/ai';
import *as IoIcons from 'react-icons/io';
import *as RiIcons from "react-icons/ri";
import SettingsIcon from '@material-ui/icons/Settings';
import DriveEtaIcon from '@material-ui/icons/DriveEta';



export const SidebarData =[



    {
    
        title: "Home",
        path: "/",
        icon:<AiIcons.AiFillHome/>,
        cName:"nav-text"
    },
    {
    
        title: "Caregivers",
        path: "/caregiver",
        icon:<RiIcons.RiNurseFill/>,
        cName:"nav-text"
    },
    {
    
        title: "Drivers",
        path: "/driver",
        icon:<DriveEtaIcon/>,
        cName:"nav-text"
    },
    {
    
        title: "Messages",
        path: "/messages",
        icon:<FaIcons.FaEnvelopeOpenText/>,
        cName:"nav-text"
    },
    {
    
        title: "Invoices",
        path: "/invoices",
        icon:<IoIcons.IoIosPaper/>,
        cName:"nav-text"
    },
    {
    
        title: "Products",
        path: "/products",
        icon:<FaIcons.FaCartPlus/>,
        cName:"nav-text"
    },
    
    
    {
    
        title: "Settings",
        path: "/team",
        icon:<SettingsIcon/>,
        cName:"nav-text"
    },
    {
    
        title: "Support",
        path: "/support",
        icon:<IoIcons.IoMdHelpCircle/>,
        cName:"nav-text"
    }
]
