import React, { useState } from 'react'
import {Grid, Typography,makeStyles } from '@material-ui/core'
import Select from '../../../UserNav/components/reuseable/Select'
import Input from '../../../UserNav/components/reuseable/input'
import Radio from '../../../UserNav/components/reuseable/radio'
import CheckBox from '../../../UserNav/components/reuseable/checkbox'

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer',
        },
    },
    textField:{

        '& .MuiFormControl-root': {
            width: '40%',
            margin: "30px 40px 40px"
        },
       
      },
    textField1:{

        '& .MuiFormControl-root': {
            width: '30%',
            margin: "16px"
        },
       
      },
    textField2:{

        '& .MuiFormControl-root': {
            width: '30%',
            margin: "32px"
        },
       
      },
}))

const careOptions = [
    { id:"male", title:"Male"},
    { id:"female", title:"Female"},
    { id:"other", title:"Other"},
]


export default function SeniorSteps(records,filterFn) {

    const classes = useStyles();



    const StepContent1 = () => {

       

        return (
            <Grid className={classes.textField} container spacing="4" direction="column" style={{margin:"40px", padding:"20px"}} >
                <Typography style={{ margin:"40px "}} variant="h3"> Welcome to VT Exquisite Home Care!</Typography>
                <Typography style={{ margin:"40px 40px 10px"}} variant="h6">What type of care are you interested in?</Typography>
                <Select style={{margin:"40px"}} label="Care" name="careOptions" options={careOptions} />
                
            </Grid>



        )
    }

    const StepContent2 = () => {

       

        return (
            <Grid container className={classes.textField2}  direction="column" style={{margin:"40px", padding:"20px" }}>
                <Typography variant="h6">What kind of help are you looking for ?</Typography>
                <Grid conatainer direction="co;umn" >
                <Grid item xs={12} md={8} lg={8} xl={8}>
                <CheckBox  label='Household task' name="isParmanent"/>
                <CheckBox  label='Transportation' name="isParmanent"/>
                <CheckBox  label='Companionship' name="isParmanent"/>
                <CheckBox  label='Specialized care' name="isParmanent"/>
                </Grid>
                <Grid item xs={12} md={8} lg={8} xl={8}>
                <CheckBox  label='Mobility assistance' name="isParmanent"/>
                <CheckBox  label='Personal care' name="isParmanent"/>
                <CheckBox  label='Memoryloss' name="isParmanent"/>
                <CheckBox  label='Hospital vists' name="isParmanent"/>
                </Grid>
                </Grid>
                
            </Grid>
        )
    }
    const StepContent3 = () => {

       

        return (
            <Grid container  className={classes.textField}  direction="column" style={{margin:"40px", padding:"20px"}} >
                <Typography style={{ margin:"40px 40px 10px"}} variant="h6">Where do you need care ?</Typography>
                <Input label="Zip code" name="mobile"  />
                
            </Grid>
        )
    }
    const StepContent4 = () => {

       

        return (
            <Grid container className={classes.textField1} direction="column" style={{margin:"40px", padding:"20px"}} >
                <Typography style={{ margin:"16px"}} variant="h6">What is your name ?</Typography>
               <Input label="First Name" name="mobile"  />
               <Input label="Last Name" name="mobile"  />
                
            </Grid>
        )
    }
    const StepContent5 = () => {

       

        return (
            <Grid container className={classes.textField} direction="column" style={{margin:"40px", padding:"20px"}}>
                <Typography variant="h6">What is your name ?</Typography>
               <Input label="First Name" name="mobile"  />
               <Input label="Last Name" name="mobile"  />
                
            </Grid>
        )
    }
    const StepContent6 = () => {

       

        return (
            <Grid container className={classes.textField} direction="column" style={{margin:"40px", padding:"20px"}}>
                <Typography variant="h6">Please enter a password to create your profile </Typography>
               <Input label="Password" name="mobile"  />
                
            </Grid>
        )
    }

   

    return {
       StepContent1,
       StepContent2,
       StepContent3,
       StepContent4,
       StepContent5,
       StepContent6,
    }
}
