import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton, Button } from '@material-ui/core'
import CallIcon from '@material-ui/icons/Call';


const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    dialogButton: {

     "& .MuiButton-contained":{
        backgroundColor:" #24f053",
             
            },
          
    },
    titleIcon: {
        // backgroundColor: theme.palette.secondary.light,
        color:" #24f053",
        '&:hover': {
            // backgroundColor: theme.palette.secondary.light,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '8rem',
        }
    }
}))

export default function CallDialog(props) {

    const { confirmDialog, setConfirmDialog } = props;
    const classes = useStyles()

    return (
        <Dialog open={confirmDialog.isOpen} className={classes.dialogButton} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton disableRipple className={classes.titleIcon}>
                    <CallIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle}
                    
                </Typography>
                <a href="tel:+1-516-514-4882">516-514-4882</a>
               
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button style={{color:"#157d2d"}}  variant="contained" color="#24f053" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} > Done</Button>
               
            </DialogActions>
        </Dialog>
    )
}
