/*eslint-disable*/
import React, {useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import Login from "mystuff/views/login.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import { useDispatch } from 'react-redux';
import {useHistory} from 'react-router-dom'
import CallDialog from '../../UserNav/components/reuseable/callDialog'

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);


export default function HeaderLinks(props) {

  const history = useHistory();

//For Dialog///////////////////
const emails = ['username@gmail.com', 'user02@gmail.com'];
const [open, setOpen] = React.useState(false);
const [selectedValue, setSelectedValue] = React.useState(emails[1]);
const [callDialog, setCallDialog] = useState({ isOpen: false, title: '', subTitle: '' })

const handleClickOpen = () => {
  setOpen(true);
history.push("/auth")
};

const handleClose = (value) => {
  setOpen(false);
  setSelectedValue(value);
};
////////////////////////////////////


const callSubmit = e => {
  e.preventDefault()

  
    setCallDialog({
      isOpen: true,
      title: 'Please Call For Assistance',
      // subTitle: "(516)-514-4882",
      onConfirm: () => { closeDialog() }
  })}
  const closeDialog = id => {
    setConfirmDialog({
        ...confirmDialog,
        isOpen: false
    })
    
  }
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Other Care Types"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}> Child Care </Link>, <a href="" target="_blank" className={classes.dropdownLink} > Pet Care </a> ]} /> </ListItem>
      <ListItem className={classes.listItem}>
        <Button href="" color="transparent" target="_blank" className={classes.navLink} >
          <CloudDownload className={classes.icons} /> Download
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        {/*<Tooltip title="Delete"> <IconButton aria-label="Delete"> <DeleteIcon /> </IconButton> </Tooltip>*/}
        <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button href="" target="_blank" color="transparent" className={classes.navLink} >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button color="transparent" href="" target="_blank" className={classes.navLink} >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button color="transparent" href="" target="_blank" className={classes.navLink} >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
          
        </Tooltip>
      </ListItem>
     
      <ListItem className={classes.listItem}>

      <Button  color="transparent" size="md" target="_blank" >
              Signin 
            </Button>
            
      </ListItem>
      <ListItem className={classes.listItem}>

<Button
        color="primary"
        size="md"
        target="_blank"
        onClick= {callSubmit}
      >
         call Us!
      </Button>
      <CallDialog confirmDialog={callDialog} setConfirmDialog={setCallDialog} />
</ListItem>

      

      
    </List>
  );
}
