

const authReducer = (state = { authData: null }, action) => {
  switch (action.type) {
    case "AUTH":
      // localStorage.setItem('profile', JSON.stringify({ ...action?.data }));// This is used to store the user login in the browser so google knows that the user is logged in

      return { ...state, authData: action.data, loading: false, errors: null };
    case "LOGOUT":
      localStorage.clear();

      return { ...state, authData: null, loading: false, errors: null };
    default:
      return state; /// This "state" is the old state of the app before any changes are made 
  }
};

export default authReducer;