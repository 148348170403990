
export const TableData =[

    {
        Status: "Overdue",
        Due:"10 Days ago",
        Date: " 09/24/2020",
        Number: "001",
        Customer:"King Shane",
        AmountDue:"$2300",
        Actions:"Make Payment"
    },
    
    {
        Status: "Overdue",
        Due:"10 Days ago",
        Date: " 09/24/2020",
        Number: "001",
        Customer:"King Shane",
        AmountDue:"$2300",
        Actions:"Make Payment"
    },
    
    {
        Status: "Overdue",
        Due:"10 Days ago",
        Date: " 09/24/2020",
        Number: "001",
        Customer:"King Shane",
        AmountDue:"$2300",
        Actions:"Make Payment"
    },
    
    {
        Status: "Overdue",
        Due:"10 Days ago",
        Date: " 09/24/2020",
        Number: "001",
        Customer:"King Shane",
        AmountDue:"$2300",
        Actions:"Make Payment"
    },
    
    {
        Status: "Overdue",
        Due:"10 Days ago",
        Date: " 09/24/2020",
        Number: "001",
        Customer:"King Shane",
        AmountDue:"$2300",
        Actions:"Make Payment"
    },
    
    
    
    
    
]