import { AppBar, Badge, Grid, IconButton, InputBase, makeStyles, Toolbar } from '@material-ui/core'
import React from 'react'
import NotificationsIcon from '@material-ui/icons/Notifications';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles( theme =>({

    root: {

        backgroundColor: "#fff"
    },

    searchInput:{
        opacity: "0.6",
        padding:"0px 8px",
        fontSize:"0.8rem",
        '&:hover':{
            backgroundColor: "#f2f2f2"
        },
        '& .MuiSvgIcon-root': {

            marginRight: theme.spacing(1)
        }


    }
}))


//// To customize things in material ui : go to the website and read up on it  in particular "mui" this is the class names given to must of the componets 
//// All so when dealing with these components, some of them have root and label. Root is the parent/ outter component and label is the child/ sub component
/// classes= {{root: classes.btnroot}, label: classes.btnroot}- two different classes being used here. One for the class that was creatd and the othe is an actual function used to change the label and root

export default function Header() {


    const classes = useStyles();
    return (
     <AppBar position= "static" className={classes.root}>
     <Toolbar>

     <Grid container alignItems="center">

         <Grid item  >
         <InputBase className={classes.searchInput} placeholder="Search" startAdornment={<SearchIcon fontSize="small"/>}/>

         </Grid>
         <Grid item sm/>
         <Grid item >
            <IconButton>

                    <Badge badgeContent={2} color="secondary">
                        <NotificationsIcon fontSize="small"/>
                    </Badge>
             </IconButton>
            <IconButton>

                    <Badge badgeContent={2} color="secondary">
                        <ChatBubbleOutlineIcon fontSize="small"/>
                    </Badge>
            </IconButton>

            <IconButton>
            <ExitToAppIcon/>

            </IconButton>


         </Grid>
     </Grid>
     </Toolbar>


     </AppBar>
    )
}
