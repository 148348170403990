import { FormControl, FormControlLabel, makeStyles,Checkbox as MuiCheckbox } from '@material-ui/core'
// import { CheckBox } from '@material-ui/icons'

import React from 'react'



export default function Checkbox(props) {

    const {name, value, onChange, label}=props
   
    const convertToDefEvent = (name, value)=>({

        target:{

            name,value //// This is the same as say name: name & Value: Value
        }
    })
    return (
        <FormControl>
        <FormControlLabel control={<MuiCheckbox name={name} color="primary" checked={value} onChange={event => onChange(convertToDefEvent(name, event.target.checked))}/>} label={label}/>


       
        </FormControl>
    )
}
