

export const ReviewData =[

    {
        Heading: "Great Staff",
        Para:"Vt Exquisite has exceeded my expectations. The staff treated us with so much respect and ensured that my parents were comfortable while caring for them. Any questions that I had staff had the answers to them which made me feel at ease. THANK YOU VT EXQUISITE AND STAFF!",
        image: require("./woman1.jpeg"),
        name: "Lisa Simpson",
      
    },
    {
        Heading: "Fantastic",
        Para:"I would highly recommend VT Exquisite! I have nothing but great things to say abut the service that was provided. From my experience I entrust that you are in good hands. Not only are they have affordable prices, they made my loved ones like their own. They’re amazing and very accommodating. ",
        image: require("./woman2.jpeg"),
        name: "Ashley Williams",
      
    },
    {
        Heading: "Professional",
        Para:"VT Exquisite left a huge impact with their outstanding services. They were so responsive when I needed to find a caregiver that would be able to assist with the needs for my dad; their choice of caregiver was phenomenal. Super compassionate, professional, and a blessing.",
        image: require("./man1.jpeg"),
        name: "Andy Brown",
      
    },
   
   

    
    
    
    
    
]