import React, { useRef, useState }  from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// import { Link } from "react-router-dom";
// @material-ui/core components
import {   makeStyles } from "@material-ui/core/styles";
import {  Grid, Typography, Avatar } from "@material-ui/core";
// @material-ui/icons
// import UpdateIcon from "@material-ui/icons/Update";
// core components
import Header from "components/Header/Header.js";
// import Icontap from "components/icontap/icontap.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Buttons from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/components.js";
import Card from "components/Card/Card.js";
import { Button } from '@material-ui/core';
import Input from 'UserNav/components/reuseable/input'
import ConfirmDialog from 'UserNav/components/reuseable/dialog'
import CallDialog from 'UserNav/components/reuseable/callDialog'
import LearnDialog from 'UserNav/components/reuseable/learnDialog'
import DatePicker from 'UserNav/components/reuseable/datepicker'
import {useForm, Forms} from '../../mystuff/views/myforms'
import { useDispatch } from 'react-redux';
// import {useHistory} from 'react-router-dom'



import { CordData } from "../../mystuff/views/cardmap.js";
import { ReviewData } from "../../UserNav/components/Data/reviewsData";
import { appointment } from "ownCode/actions/appointment";


console.log(CordData)



const useStyles = makeStyles(styles);
const useStyle = makeStyles(theme => ({
  root: {
      '& .MuiFormControl-root': {
          width: '80%',
          margin: theme.spacing(1)
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "green"
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "white",
          color: "red"
        },
        "&:hover fieldset": {
          border:" 3px solid rgb(251 251 251)"
         
        },
        "&.Mui-focused fieldset": {
          border:" 3px solid rgb(251 251 251)"
        }
      },

      "& .MuiButton-outlined":{
        border:" 1px solid rgb(251 251 251)",
        width: '80%'
       
      },
      "& .MuiInputBase-input":{
        color:" white"
       
      },
      "& .MuiInputLabel-outlined ":{
        color:" white"
       
      },
  },
  textField:{

    "& .MuiButton-outlined":{
      border:" 1px solid rgb(251 251 251)",
     
    },
   
  },
  textField1:{

    textAlign:"center",

    "& .MuiButton-outlined":{
      border:" 1px solid rgb(251 251 251)",
      width: '40%'
  
    },
 
  },
  roundButon:{

    "& .MuiButton-contained":{
      borderRadius:"20px",
      
     
     
    },
    "& .MuiButton-containedPrimary":{
      backgroundColor:"#5fb806",
    
    },
   

  },
  overflow:{

    overflowX:"inherit"
     
    },

    hideImage:{
      "@media screen and (max-width: 600px)": {
        display: "none" 
      }
    },
    firstSection:{
      "@media screen and (min-width: 992px)": {
        padding:"120px",
        
       h2: { 
         fontSize: "45px",
         
          }
      },
      "@media screen and (max-width: 768px)": {
        padding:"15px",
      
      }
    },
    headings:{
      "@media only screen and (min-width: 996px)": {
          fontSize: "4.2rem",
          fontWeight: "600",
          display: "inline-block",
          position: "relative",
      },
      "@media only screen and (max-width: 768px)": {
          display: "none",
      }
    },
    middleHeadings:{
     
      "@media only screen and (max-width: 768px)": {
          fontSize: "25px",
          paddingTop:"20px"
      }
    },
    middlePadding:{
      "@media only screen and (min-width: 996px)": {
        padding:"40px"
    },
     
      "@media only screen and (max-width: 768px)": {
          
          padding:"0px"
      }
    },
    card:{
        
            
        
      '&:hover': {
          // border: [
          //   [1, 'solid', 'blue']
          // ],
          //
          //  boxShadow: [
          //     [2, 2, 2, 1, '#e38c09'],
              
          //   ],
            transform: "scale(1.1)"
          
          // backgroundColor:"#e38c09",
          // borderRadius:"200px",
         

      },
    }


}));

const initialFValues = {
  id: 0,
  fullName: '',
  email: '',
  mobile: '',
  message:'',
  appDate: new Date(),
}





export default function Components(props) {


  const classes = useStyles();
  const classe = useStyle();
  const titleRef = useRef();
  const dispatch = useDispatch();
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
  const [callDialog, setCallDialog] = useState({ isOpen: false, title: '', subTitle: '' })
  const [learnDialog, setLearnDialog] = useState({ isOpen: false, title: '', subTitle: '' })

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('fullName' in fieldValues)
        temp.fullName = fieldValues.fullName ? "" : "This field is required."
    if ('message' in fieldValues)
        temp.message = fieldValues.message ? "" : "This field is required."
    if ('email' in fieldValues)
        temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email is not valid."
    if ('mobile' in fieldValues)
        temp.mobile = fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required."
    if ('departmentId' in fieldValues)
        temp.departmentId = fieldValues.departmentId.length !== 0 ? "" : "This field is required."
    setErrors({
        ...temp
    })

    if (fieldValues === values)
        return Object.values(temp).every(x => x === "")
}

const closeDialog = id => {
  setConfirmDialog({
      ...confirmDialog,
      isOpen: false
  })
  
}
const callSubmit = e => {
  e.preventDefault()

  
    setCallDialog({
      isOpen: true,
      title: 'Please Call For Assistance',
      // subTitle: "(516)-514-4882",
      onConfirm: () => { closeDialog() }
  })

  
      
}
const learnSubmit = e => {
  e.preventDefault()

  
    setLearnDialog({
      isOpen: true,
      title: 'More than just home health care',
      subTitle: vtparagraph,
      onConfirm: () => { closeDialog() }
  })

  
      
}



const handleSubmit = e => {
    e.preventDefault()

    console.log(values)
    if (validate()) {

      // handleClose();
      
      // console.log(input);
     

      const newAppointment={
        name: values.fullName,
        email: values.email,
        moblie: values.mobile,
        date: values.appDate,
        message: values.message,


      }
      // Axios.post("http://localhost:5000/register", newUser)
      dispatch(appointment(newAppointment));
      console.log(newAppointment)
      // console.log(history)
      // history.push("/login-page")
      setConfirmDialog({
        isOpen: true,
        title: 'Appointment Schedule Sucessfully',
        subTitle: "Plesae check email for confirmation",
        onConfirm: () => { closeDialog() }
    })

    
         resetForm()
    }
}

  
const {values,errors, resetForm, setErrors, handleInputChange}= useForm(initialFValues, true, validate);



  const { ...rest } = props;
  const vtparagraph =
    "VT Exquisite Home Health Care provides the best in home care for the residents of New York Queens,  Brooklyn and Long Island. Our caregivers will assist you with your personal needs and daily activities. " +
    "Committed to our community, VT Exquisite Home Health Care is one of the few locally owned non-franchise home care companies in New York. Our dedication to several elder care focused non-profit organizations allows Adult Care Assistance Home Care to direct New York seniors to affordable and FREE Senior Services. We accept all insurance plan including Medicaid.";
  return (
    <div>
      <Header
        brand="VT Exquisite Home Care"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{ height: 400, color: "white" }}
        {...rest}
      />
      <Parallax image={require("assets/img/V2.jpg")}>
        <div className={classes.container}>
          <GridContainer className={classe.textField}>
            <GridItem>
              <div className={classes.brand}>
                <h3 ref={titleRef} className={classe.headings}>VT Exquisite Home Care</h3>
                <h3 className={classes.subtitle} style={{marginBottom:"30px"}}>
                  Caring with our undivided attention
                </h3>
                <Buttons style={{margin:"20px 20px 20px 0px"}}
              color="google"
              size="lg"
              target="_blank"
            >
              Schedule An Apppointment!
            </Buttons>
            <Button style={{margin:"20px", color:"#ffffff"}}
            variant="outlined"
              color="#f20000"
              size="large"
             onClick={learnSubmit}
            >
              Learn more about  Us!
            </Button>
            <LearnDialog confirmDialog={learnDialog} setConfirmDialog={setLearnDialog} />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <GridContainer  className={classes.textCenter} justify="center">
          <GridItem  className={classe.hideImage} style={{ padding: "120px" }} xs={12} sm={12} md={4} lg={4}>
            <img
              height="300"
              xs={12}
              src={require("assets/img/undraw_medical_care_movn.svg")}
              alt="Girl in a jacket"
            ></img>
            
          </GridItem>

          <GridItem className={classe.firstSection} xs={12} sm={12} md={8} lg={8}>
            <h2  className={classe.firstSection.h2}>
              Welcome to VT Exquisite  Home Health Care
            </h2>
            <p style={{ lineHeight: "50px" }}>{vtparagraph}</p>
          </GridItem>
        </GridContainer>

       

        <div className={classes.section}  style={{ marginTop:"40px",
            textAlign: "center",
            padding: "10px",
            // backgroundColor:"#74f291",
          }} >
          <div className={classes.container}>

            <div style={{ margin: "auto" }}>
                <h1 style={{color:"black"}}>OUR HEALTH CARE SERVICES</h1>
                <hr style={{margin: "auto", width: "300px", border:"1", color:"black" }}></hr>
                <p style={{color:"black"}}>
                  {" "}
                  Didn’t Find what you were looking for? Here are some more services
                  we provide{" "}
                </p>
            </div>
            
            
          </div>
          
        
        </div>
        <Grid container className={classe.roundButon} justify="center" style={{marginTop:"50px", marginBottom:"300px"}}>
              
                {CordData.map((item, index) => {
                  return (
                 
                    <Card key={index} className={classe.card} style={{border:"1px solid #5fb806", width:"380px", margin:"30px", height:"250px", backgroundImage:"url(" +item.icon+")", backgroundSize:"200px", backgroundRepeat:"no-repeat", backgroundPosition:"right center" }}>
                    
                    <GridContainer styles={{padding:"10px"}} justify="center" direction="column">
                      <Typography align="left"  variant="h6" style={{ margin:"30px"}}>{item.header}</Typography>
                      <Typography align="left" color="textSecondary" variant="body1" style={{ margin:"5px", maxWidth:"160px", marginLeft:"30px"}}>{item.paragraph}</Typography>
                     
                      <GridContainer styles={{padding:"10px"}}  direction="row">
                      <Button align="left" style={{margin:"20px", barderRadius:"10px" }} onClick={callSubmit} justify="center" variant="contained" color="primary" size="large" > Find Care </Button>
                      <CallDialog confirmDialog={callDialog} setConfirmDialog={setCallDialog} />
                        </GridContainer>
                        </GridContainer>                        
                    </Card>
                  
                  );
                })}
              
                </Grid>
        <GridContainer  className={classe.middlePadding} style={{ backgroundColor:"#f8941e",  height:"auto"}} justify="Space-evenly" >
          
               <GridItem   className={classe.textField1} xs={12} md={6} lg={6}>
               <GridContainer styles={{padding:"150px"}} justify="center" alignItems="center" direction="column">
                    <Typography className={classe.middleHeadings} align="center" variant="h3" style={{color:"#ffffff"}}>Sign up today to see all<br/> of the services that <br/>we provide </Typography>
                    <Button style={{ margin:"60px", color:"#ffffff", minWidth:"200px"}}
                    variant="outlined"
                      color="#f20000"
                      size="large"
                    
                    >
                      Sign up!
                    </Button>
                    </GridContainer>
                </GridItem>
               <GridItem style={{ textAlign:"center"}} xs={12} md={6} lg={6}>
               <Typography align="center" variant="h6" style={{color:"#ffffff", marginBottom:"30px"}}>Schedule An Appointment Today! </Typography>
                  <Forms  onSubmit={handleSubmit} className={classe.root} style={{minWidth:"300px"}} >
                      <GridContainer styles={{marginBottom:"40px"}} alignItems="center" justify="center">
                      
                          <GridItem xs={12} md={6} lg={6} xl={6}>
                          <Input className={classe.textField}  label="Full Name" name="fullName" value={values.fullName} onChange={handleInputChange} error={errors.fullName} ></Input>
                              <Input className={classe.textField}  label="Email" name="email" value={values.email} onChange={handleInputChange} error={errors.email}  ></Input>
                              <Input  className={classe.textField} label="Phone Number" name="mobile" value={values.mobile} onChange={handleInputChange} error={errors.mobile} ></Input>
                              <DatePicker className={classe.textField}  label="Appointmet Date" value={values.appDate} onChange={handleInputChange} name="appDate"/>
                          </GridItem>
                    <GridItem style={{ textAlign:"center"}} xs={12} md={6} lg={6} xl={6}>
                    {/* <GridContainer style={{marginBottom:"40px"}} alignItems="center" justify="center"> */}
                   
                        <Input multiline rows={8} label="How can we help?" name="message" value={values.message} onChange={handleInputChange} error={errors.message} ></Input>
                    
                        <Button style={{margin:"20px", color:"#ffffff"}}  onClick={handleSubmit} variant="outlined" color="#f20000" size="large" > Submit! </Button>
                        <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
                       
                        {/* </GridContainer> */}
                    </GridItem>
                
              

                </GridContainer>
             </Forms>

             </GridItem>
            
            </GridContainer>

        <div style={{marginTop:"100px", marginBottom:'300px' }} className={classes.container} >

            <div >
                  <Typography  align="center" variant="h3" style={{ marginBottom:"30px", marginTop:"30px",  }}>What Our Clients Have to Say </Typography>
                  <Typography align="center"  variant="body1" style={{ marginBottom:"30px"}}>Some kind words from our respected clients </Typography>
                      
                  </div>

                  <GridContainer  alignItems="center" alignContent="center" style={{marginTop:"50px", }} justify="Space-evenly" spacing={0}>
                    
                      {ReviewData.map((item, index) => {
                        return (
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <div key={index} style={{width:"300px"}}>
                            <div style={{ alignContents: "center", padding: "10px" }}>
                            <Typography align="center"  variant="h6" style={{ marginBottom:"30px"}}>{item.Heading} </Typography>
                            <Typography  variant="body1" style={{ marginBottom:"60px", textAlign:"center"}}>{item.Para}</Typography>
                            <Avatar alt="Remy Sharp" src={item.image} style={{height:"200px", width:"200px", margin:"20px", alignText:"center"}} />
                            <Typography align="center"  variant="body1" style={{ marginBottom:"30px"}}>{item.name}</Typography>
                            </div>
                          </div>
                          </GridItem>
                        );
                      })}
                    
                      </GridContainer>

                      
                    


            </div>
            

            <GridContainer  style={{marginTop:"50px", backgroundColor:"#f8941e",  height:"auto", padding:"80px"}} alignItems="center" justify="center" >
           
          
            <GridItem className={classe.root} xs={12} md={4}>
                <img
                  height="100"
                  xs={12}
                  src={require("assets/img/nav.svg")}
                  alt="Girl in a jacket"
                ></img>
                <Typography   variant="h6" style={{ margin:"20px", color:"#ffffff"}}>Our Office locaton:</Typography>
                <Typography  variant="subtitle1" style={{margin:"20px",color:"#ffffff", minWidth:"170px"}}> 470 Lakeville Rd <br/>
                New Hyde Park, NY 11429 <br/>  Call us: (516)-605-4719
                </Typography>
                
                
      
                </GridItem>
                <GridItem xs={12} md={4}>
                <Typography variant="h6" style={{ margin:"0 20px 20px", color:"#ffffff"}}>Useful Links</Typography>
                <Typography variant="subtitle1" style={{ margin:"20px", color:"#ffffff"}}>Signup</Typography>
                <Typography variant="subtitle1" style={{ margin:"20px", color:"#ffffff"}}>Careers</Typography>
                <Typography variant="subtitle1" style={{ margin:"20px", color:"#ffffff"}}>News</Typography>
                <Typography variant="subtitle1" style={{ margin:"20px", color:"#ffffff"}}>Appointment</Typography>
             


                 </GridItem>
                <GridItem xs={12} md={4}>
          
                  <Typography align="center"  variant="h6" style={{color:"#ffffff", marginBottom:"30px"}}>Subscribe to our newsletter! </Typography>
                  <Forms onSubmit={handleSubmit} className={classe.root} styles={{ minWidth:"300px"}} >
                      <GridContainer styles={{marginBottom:"40px", minWidth:"300px"}} alignItems="center" justify="center">
                      
                              <Input style={{ minWidth:"250px"}} className={classe.textField} label="Name" name="fullName" value={values.fullName} onChange={handleInputChange} error={errors.fullName}></Input>
                              <Input style={{ minWidth:"250px"}} className={classe.textField}  label="Email" name="email" value={values.email} onChange={handleInputChange} error={errors.email}></Input>
                             
                              <Button style={{margin:"20px", color:"#ffffff", minWidth:"250px"}} onClick={handleSubmit}
                              variant="outlined"
                                color="#f20000"
                                size="large"
                              >
                                Submit!
                              </Button>
                             
                            </GridContainer>
                  </Forms>

                </GridItem>
            
            </GridContainer>

              
            
        
        
        {/* <div style={{ margin: "150px" }}></div> */}

      
        
      </div>
      
      <Footer />
    </div>
  );
}
