import React, { useState } from 'react'
import SeniorSteps from './Steps/SeniorSteps'
import Stepper from '../../UserNav/components/reuseable/stepper'
import {Grid, Typography,makeStyles } from '@material-ui/core'
import Select from '../../UserNav/components/reuseable/Select'
import Input from '../../UserNav/components/reuseable/input'

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer',
        },
    },
}))

const Stepz =['Type of care', 'Care options', 'Zip code','Personal Info', 'Sign up', 'Create password'];
export default function SeniorSignup() {
  
    const { stepContent1,stepContent2,stepContent3,stepContent4,stepContent5,stepContent6 } = SeniorSteps();
    console.log(stepContent1)

    return(

    <Stepper   Stepz={ Stepz} />
    
    )
}
