
export const WorkersData =[

    {
        Name: "King Shane",
        Rating:"5 Star",
        Years_of_experience: " 7 Years of Exp.",
        Rate: "$45",
        Active:"2 hours ago"
        
    },
    {
        Name: "King Shane",
        Rating:"5 Star",
        Years_of_experience: " 7 Years of Exp.",
        Rate: "$45",
        Active:"2 hours ago"
        
    },
    {
        Name: "King Shane",
        Rating:"5 Star",
        Years_of_experience: " 7 Years of Exp.",
        Rate: "$45",
        Active:"2 hours ago"
        
    },
    {
        Name: "King Shane",
        Rating:"5 Star",
        Years_of_experience: " 7 Years of Exp.",
        Rate: "$45",
        Active:"2 hours ago"
        
    },
    {
        Name: "King Shane",
        Rating:"5 Star",
        Years_of_experience: " 7 Years of Exp.",
        Rate: "$45",
        Active:"2 hours ago"
        
    },

]