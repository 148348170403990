
import React from 'react'
import {  Grid, Typography,makeStyles,Card } from '@material-ui/core'

import {CordData} from '../../mystuff/views/cardmap'




const useStyles = makeStyles(theme=>({

    paperContent:{

        margin: theme.spacing(0),
        padding: theme.spacing(5)
    },
    container:{
        overflowY: "scroll",
        display:"flex",
        height:"90vh",
        // backgroundColor:"#e9ebf0"
    },

    card:{
        
            
        
        '&:hover': {
            // border: [
            //   [1, 'solid', 'blue']
            // ],
            //
            //  boxShadow: [
            //     [2, 2, 2, 1, '#e38c09'],
                
            //   ],
              transform: "scale(1.1)"
            
            // backgroundColor:"#e38c09",
            // borderRadius:"200px",
           

        },

        gridContent:{

            margin: theme.spacing(2),
            padding: theme.spacing(4)
        },
    }



}))

export default function Home() {

    const classes = useStyles();
    return (
       

        <Grid  className={classes.container} container spacing="evenly">

            {/* <Grid className={classes.paperContent} item xs={12} md={4} alignItems="center">
            
            <Typography variant="h6"> News</Typography>
            <NewsPost/>
            <NewsPost/>
            <NewsPost/>
            
                
            </Grid> */}
            
            <Grid className={classes.paperContent} style={{ marginTop:"50px", align:"center"}} item xs={12} md={12} >
            {/* <Paper style={{ padding:"50px", align:"center"}}> */}
            <Typography variant="h6"  align="center"> Good morning Shane! What would you like to do today?</Typography>

            <Grid container style={{marginTop:"50px", }} justify="Space-evenly" spacing={4}>
              
                {CordData.map((item, index) => {
                  return (
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Card  className={classes.card} key={index} elevation="5">
                      <div style={{ textAlign: "center", padding: "10px" }}>
                        {item.icon}

                        <h4>{item.header}</h4>

                        <p>
                         {item.paragraph}
                        </p>
                      </div>
                    </Card>
                    </Grid>
                  );
                })}
                
              
            </Grid>
                
                {/* </Paper> */}
                    

            </Grid>


        </Grid>
    )
}









