import React from 'react'
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import { makeStyles } from '@material-ui/core';
import Home from './pages/Home'
import Invoices from './pages/invoices'
import Driver from './pages/driver'
import Caregiver from './pages/Caregiver'
import Appbar from './components/Appbar'
import Sidemenu from "./components/sidemenu"

 const useStyles = makeStyles({
    appMain: {
    
      
     height:"100vh"
   
   
    },
    sideGrid:{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        width:"12%",
      
    },
    middleGrid:{
      

      paddingLeft:"12%",
      width: "100%",
      backgroundColor:"white"
      
    }
   
   
   })

function NavMenu() {
  const classes = useStyles();

  
  return (
   <>
   <Router>
    <div  >
    
    <div   >
    <Sidemenu/>
    </div>
    <div className={classes.middleGrid}  >
   
        <Appbar/>

        <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/driver"component={Driver}/>
        <Route path="/invoices"component={Invoices}/>
        <Route path="/caregiver"component={Caregiver}/>
      </Switch>
      

    </div>

    </div>
  

   </Router>

   </>
  );
}

export default NavMenu;
